import { useSpring, animated } from "react-spring"

import React from "react"

const SlideInAnimation = props => {
  let directionFrom, directionTo

  if (props.direction === "left") {
    ;[directionFrom, directionTo] = [
      { opacity: 0, marginLeft: "1000px" },
      { opacity: 1, marginLeft: "0px" },
    ]
  } else {
    ;[directionFrom, directionTo] = [
      { opacity: 0, marginRight: "1000px" },
      { opacity: 1, marginRight: "0px" },
    ]
  }

  const propsSpring = useSpring({
    from: directionFrom,
    to: directionTo,
  })
  return (
    <div>
      <animated.div style={propsSpring}>{props.children}</animated.div>
    </div>
  )
}

export default SlideInAnimation
