import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

// importing componenets
import ScrollDown from "../ScrollDown/scrollDown"
import Img from "gatsby-image"

// importing assets
import "./hero-image.scss"
import SlideInAnimation from "../SlideInAnimation/slideInAnimation"

const HeroImage = props => {
  const data = useStaticQuery(graphql`
    query {
      splashHeroImage: file(relativePath: { eq: "splashHeroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ladyHeroImage: file(relativePath: { eq: "ladyHeroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SlideInAnimation direction="left">
      <div className="hero-image">
        <div className="title-container">
          <div>{props.title}</div>
        </div>
        <div className="images-container">
          <div className="splash-hero-image">
            <Img fluid={data.splashHeroImage.childImageSharp.fluid} />
          </div>
          <div className="lady-hero-image">
            <Img fluid={data.ladyHeroImage.childImageSharp.fluid} />
          </div>
        </div>
        <ScrollDown href={props.hrefForScrollDown} />
      </div>
    </SlideInAnimation>
  )
}
HeroImage.defaultProps = {
  title: "NO TITLE",
}
HeroImage.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HeroImage
