import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/utilities/HeroImage/heroImage"
import AboutUsDes from "../components/aboutUs/aboutUsDes"
import SupportBrands from "../components/utilities/SupportBrands/supportBrands"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <HeroImage title="About Us" hrefForScrollDown="/about/#about-us-des" />
    <AboutUsDes />
    <SupportBrands />
  </Layout>
)

export default AboutPage
