import React from "react"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

// importing components
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// importing assets
// import "./scroll-down-component.scss"

const ScrollDown = props => {
  const style = {
    position: "absolute",
    bottom: 0,
    textAlign: "center",
    width: "100%",
    zIndex: 10,
  }
  return (
    <div className="scroll-down" style={style}>
      <Link to={`${props.href}`}>
        <div>Scroll down</div>
        <div style={{ maxHeight: "15px" }}>
          <FontAwesomeIcon icon={faChevronDown} size="sm" />
        </div>
      </Link>
    </div>
  )
}

export default ScrollDown
