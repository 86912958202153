import React from "react"

// importing components
import Img from "gatsby-image"
import Button from "../Button/button"

// importing assets
import "./card-strip.scss"

const CardStrip = props => {
  return (
    <div
      className="card-strip "
      style={{
        backgroundColor: `${props.backgroundColor}`,
        color: `${props.backgroundColor === "#058080" ? "#fff" : "#08bfbf"}`,
        borderRadius: `${props.borderRadius}`,
      }}
    >
      <div className="container ">
        <div
          className={
            props.backgroundColor !== "#058080"
              ? "content rowCol-reverse"
              : "content rowToReverseCol"
          }
        >
          <div
            className="text"
            style={!props.gatsbyImg ? { width: "90%" } : null}
          >
            <div className="title">
              <h1>{props.title}</h1>
            </div>
            <div className="description">{props.description}</div>
            {props.buttonTo ? (
              <Button to={props.buttonTo} name={props.buttonText} />
            ) : null}
          </div>
          {props.gatsbyImg ? (
            <div className="card-img">
              <Img fluid={props.gatsbyImg} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

CardStrip.defaultProps = {
  title: "This is Title!",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
}

export default CardStrip
