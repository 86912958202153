import React from "react"
import PropTypes from "prop-types"

// importing components
import { Link } from "gatsby"

// importing assets
import "./button.scss"

const Button = props => {
  return (
    <div className="btn">
      <Link to={props.to}>
        <button>{props.name}</button>
      </Link>
    </div>
  )
}

Button.defaultProps = {
  to: "/#",
  name: "Unnamed button",
}

Button.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default Button
