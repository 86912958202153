import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Importing componenets
import CardStrip from "../utilities/CardStrip/cardStrip"

// Importing assets
import "./about-us.scss"

const AboutUsDes = () => {
  const data = useStaticQuery(graphql`
    query {
      doneshImage: file(relativePath: { eq: "donesh-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const dataArr = [
    {
      title: "Who are we?",
      description:
        "AQUIS Premium, established in 2015, by Donesh, under ‘Start Up India, Stand Up India Campaign’. We are a leading group of manufacturing Brass Hingees and Architectural hardware conforming to the accepted international standards. Our entire manufacturing activity (i.e. right from the melting of raw material in the form of imported brass scrap to the finished product) is carried out under “one roof” under the constant supervision of our able technical staff.",
      gatsbyImg: data.doneshImage.childImageSharp.fluid,
    },
    {
      title: "What we do?",
      description:
        "The group includes a metallurgical company, which does the melting of imported raw material at 920°C (brass scrap) and draws extruded brass rods (preferred to castings on account of better quality) that form raw material of our company. Strict quality tests are enforced on the products and only those products that pass the accepted standards; leave our factory. Every effort is taken to ensure the uniform quality of the finished products. This is the reason why quality conscious buyers give preference to AQUIS Premium.",
      gatsbyImg: false,
    },
    {
      title: "How we do it?",
      description:
        "AQUIS Premium, distributes the above products under its own firm named II arham traders II acknowledged to be synonymous with unmatched quality and excellent customer service, at competitive prices, a tradition of the group and a strategy for the future. We are also equipped to manufacture brass precision components as per customer drawings and samples according to the given specifications. OEM orders and customer’s designs are also welcome.",
      gatsbyImg: false,
    },
  ]
  return (
    <div id="about-us-des">
      <div className="container">
        {dataArr.map((el, ind) => {
          return (
            <CardStrip
              key={ind}
              backgroundColor="#058080"
              title={el.title}
              description={el.description}
              gatsbyImg={el.gatsbyImg}
              borderRadius="200px 0 200px 0"
            />
          )
        })}
      </div>
    </div>
  )
}

export default AboutUsDes
