import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// importing components
import Img from "gatsby-image"

// importing assets
import "./support-brands.scss"

const SupportBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      makeInIndiaImage: file(relativePath: { eq: "makeInIndiaImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      startUpIndiaImage: file(relativePath: { eq: "startUpIndiaImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="supportbrands">
      <h1>Proud Supporter of</h1>
      <div className="images-container">
        <div className="make-in-india">
          <Img fluid={data.makeInIndiaImage.childImageSharp.fluid} />
        </div>
        <div className="start-up-india">
          <Img fluid={data.startUpIndiaImage.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  )
}

export default SupportBrands
